const exportUrls = {
  downloadExport: (exportId: string) => `/api/shared/exports/${exportId}/download`,
  getMyExports: () => '/api/shared/exports/currentuser/search',
  scheduleAssetExport: () => '/api/shared/exports/asset/schedule',
  scheduleContractExport: () => '/api/shared/exports/contract/schedule',
  schedulePlanExport: () => '/api/shared/exports/plan/schedule'
};

const socketUrls = {
  connectMyExportsSocket: () => `/socket/shared/hubs/exports`
};

const noteUrls = {
  addNote: () => '/api/shared/notes',
  deleteNote: (noteId: string) => `/api/shared/notes/${noteId}`,
  updateNote: (noteId: string) => `/api/shared/notes/${noteId}`
};

const attributeUrls = {
  addAttribute: () => '/api/shared/attributes',
  deleteAttribute: (id: string) => `/api/shared/attributes/${id}`,
  getAttributeAssignmentsByObjectType: (attributeObject: string) =>
    `/api/shared/attributes/assignments/search/object-type/${attributeObject}`,
  getAttributeAssignmentsByObjectTypeAndObjectId: (attributeObject: string, objectId: string) =>
    `/api/shared/attributes/assignments/object-type/${attributeObject}/object-id/${objectId}`,
  getAttributeAssignmentsHistory: (obejctId: string) =>
    `/api/shared/attributes/assignments/history/by-object-id/${obejctId}`,
  searchAttributesByIds: () => `/api/shared/attributes/search/by-ids`,
  updateAttribute: (id: string) => `/api/shared/attributes/${id}`,
  updateMultipleObjectAttributeAssignments: () => '/api/shared/attributes/assignments',
  updateSingleObjectAttributeAssignments: (attributeObject: string, objectId: string) =>
    `/api/shared/attributes/assignments/object-type/${attributeObject}/object-id/${objectId}`
};

const cameraUrls = {
  addCamera: () => '/api/shared/cameras',
  deleteCamera: (id: string) => `/api/shared/cameras/${id}`,
  getCameraById: (id: string) => `/api/shared/cameras/${id}`,
  searchCamera: () => '/api/shared/cameras/search',
  updateCamera: (id: string) => `/api/shared/cameras/${id}`
};

export default {
  ...exportUrls,
  ...socketUrls,
  ...noteUrls,
  ...attributeUrls,
  ...cameraUrls
};
